<template>
  <div class='flashcard d-flex justify-center align-center transparent pt-5'>
    <div
      class='flashcard__inner'
      :class="flipped ? 'is-flipped': 'flashcard-general__inner'"
      @click='toggleCard'
    >
      <!-- enunciado card -->
      <div
        class='flashcard__face flashcard__face--front d-flex justify-center align-center py-5 px-10'
        :style='cssProps'
      >
        <p class="text__front text-center">
          {{ card.enunciado }}
        </p>
      </div>
      <!-- enunciado card -->
      <div
        class='flashcard__face flashcard__face--back d-flex justify-center align-center pa-5'
        :style='cssProps'
        style='z-index: 2 !important;'
      >
        <!-- show more button -->
        <div
          v-if="card.comentario || card.imagen"
          class="pa-5"
          style="position: absolute; top: 0; right: 0; z-index: 3;"
          @click.prevent.stop
        >
          <v-btn
            color="#0583DA"
            class="text-capitalize d-flex justify-center align-center flashcard__showmore--wrapper"
            :text="!smAndDown"
            @click.prevent="showComment=!showComment"
          >
            <p class="pa-0 ma-0 flashcard__showmore">
              Ver más
            </p>
            <v-icon color="primary">
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
        <!-- show more button -->
        <!-- response card -->
        <v-slide-x-transition leave-absolute>
          <p v-if="!showComment" class="text__back text-center">
            {{ card.respuesta }}
          </p>
        </v-slide-x-transition>
        <!-- response card -->
        <!-- comment -->
        <v-slide-x-transition leave-absolute>
          <div
            v-if="showComment"
            class="flashcard__comment d-flex align-center justify-space-between"
            :class="{'flex-column': smAndDown}"
          >
            <FlashcardImageViewer
              v-if="card.imagen"
              :imageSrc="card.imagen || 'https://picsum.photos/700/400?random'"
            />
            <div class="text__comment--maxlines flex-grow-1">
              <p
                v-if="card.comentario"
                class="avoid_selection text__comment text-center"
                :class="smAndDown ? 'px-10': ''"
              >
                {{ card.comentario }}
              </p>
            </div>
          </div>
        </v-slide-x-transition>
        <!-- comment -->
        <!-- footer -->
        <div
          class="flashcard__footer w-100 d-flex justify-space-around align-center"
          :class="smAndDown ? 'py-5 px-0': 'py-5 px-15'"
          @click.prevent.stop
        >
          <v-btn
            class="btn btn__previous text-capitalize"
            :disabled="back"
            @click="$emit('goTo', -1)"
          >
            Atrás
          </v-btn>
          <v-btn  class="btn btn__next text-capitalize" @click="$emit('goTo', 1)">
            Siguiente
          </v-btn>
        </div>
        <!-- footer -->
      </div>
    </div>
  </div>
</template>

<script>
import FlashcardImageViewer from '../flashcard/FlashcardImageViewer.vue'

export default {
  components: { FlashcardImageViewer },
  props: {
    card: {
      type: Object,
      default: () => ({
        area: 'ObGyn',
        comentario: '',
        enunciado: 'Tipo de pólipo que protuye por el OCE',
        es_favorito: true,
        especialidad: 'Ginecología',
        fase: 'APRENDIZAJE',
        id: 1122,
        imagen: null,
        respuesta: 'CERVICAL',
        tags: ['RM', 'ESSALUD', 'ENAM'],
        tema: 'Hemorragia uterina anormal'
      })
    },
    isActiveFc: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    flipped: false,
    showComment: false,
    backimage: '@/assets/mazos/flashcard-logo.svg'
  }),
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    cssProps () {
      if (this.smAndDown) {
        return {
          backgroundImage: `url(${require('@/assets/mazos/flashcard-logo.svg')})`,
          bakgroundSize: '200px 254px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      } else {
        return {
          backgroundImage: `url(${require('@/assets/mazos/flashcard-logo.svg')})`,
          bakgroundSize: '108px 135px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      }
    }
  },
  watch: {
    isActiveFc () {
      if (!this.isActiveFc) {
        this.flipped = false
      }
    }
  },
  methods: {
    toggleCard () {
      this.flipped = !this.flipped
    }
  }
}
</script>

<style lang='scss' scoped>
.flashcard {
  width: 90%;
  height: 412px;
  background: #FFFFFF;
  // border: 1px solid #E5E5E5;
  box-sizing: border-box;
  // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  perspective: 3500px;
  backface-visibility: hidden !important;
  transition: transform 0.2s !important;
  position: relative;
  &__inner {
    // background-image: url('@/assets/mazos/flashcard-logo.svg');
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    &.is-flipped {
      transform: rotateY(-180deg) !important;
    }
  }
  &__face {
    position: absolute !important;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
    &--front {
      background: #FFFFFF;
    }
    &--back {
      background: #FFFFFF;
      transform: rotateY(180deg)translateZ(1px);
    }
  }
  &__comment {
    width: 70%;
  }
  &__backimage {
    opacity: 0.2;
  }
  &__footer {
    position: absolute;
    bottom: 0
  }
  &__showmore {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0583DA;
    &--wrapper {
      border-radius: 10px;
    }
  }
}
.text {
  &__front {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }
  &__back {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    background: -webkit-linear-gradient(#1FC1D0, #0583DA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__comment {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    color: rgba(38, 38, 38, 0.75);
    &--maxlines {
      width: 380px;
      -webkit-line-clamp: 10;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.btn {
  height: 47px !important;
  width: 230px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  &__previous {
    background: #FFFFFF !important;
    color: #0C8CD9;
  }
  &__next {
    background: linear-gradient(270deg, #0480DA 0%, #1FC1D0 100%);
    color: #FFFFFF;
  }
}
@media (max-width: 960px) {
  .btn {
    height: 41px !important;
    width: 40%;
    font-size: 14px;
    line-height: 21px;
  }
  .text {
    &__front {
      font-size: 14px;
      line-height: 21px;
    }
    &__back {
      font-size: 14px;
      line-height: 21px;
    }
    &__comment {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .flashcard {
    &__showmore {
      font-size: 14px;
      line-height: 21px;
      &--wrapper {
        background: #FFFFFF !important;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px !important;
      }
    }
  }
}
</style>
