<template>
  <v-container class="pb-10">
    <TheDrawerInfo
      v-if="flashcards[this.activeId]"
      :area="flashcards[this.activeId].area"
      :especialidad="flashcards[this.activeId].especialidad"
      :tema="tema()"
    />
    <div class="mx-3 my-5">
      <FlashcardHeader class="d-flex d-md-none" @click="$router.go(-1)">
        {{ tema() }}
      </FlashcardHeader>
    </div>
    <div v-if="flashcards.length">
      <FlashcardFavouriteList
        :flashcards="flashcards"
        class="list"
        @setActiveId="setActiveId"
      />
    </div>
    <div v-else style="width: 90%; height: 392px;" class="mx-auto d-flex justify-center align-center" >
      <v-progress-circular indeterminate/>
    </div>
    <FlashcardLevelBarTimer
      :progress="progress"
      :level="nivel"
      :timer="timer"
    />
  </v-container>
</template>

<script>
import moment from 'moment'
import FlashcardFavouriteList from '../../components/mazos/FlashcardFavouriteList.vue'
import TheDrawerInfo from '../../components/TheDrawerInfo.vue'
import FlashcardHeader from '../../components/flashcard/FlashcardHeader.vue'
import FlashcardLevelBarTimer from '../../components/flashcard/FlashcardLevelBarTimer.vue'

import axios from 'axios'

export default {
  components: { TheDrawerInfo, FlashcardFavouriteList, FlashcardLevelBarTimer, FlashcardHeader },
  data: () => ({
    flashcards: [],
    levelBar: 0,
    offset: 20,
    level: 1,
    timer: '',
    activeId: 0
  }),
  computed: {
    progress () {
      return ((this.activeId + 1) / this.flashcards.length * 100).toFixed(2)
    },
    nivel () {
      return `${this.activeId + 1} de ${this.flashcards.length}`
    }
  },
  methods: {
    tema () {
      if (this.flashcards[this.activeId] !== undefined) {
        return this.flashcards[this.activeId].tema
      } else {
        return null
      }
    },
    setActiveId (index) {
      this.activeId = index
    },
    setTimer () {
      const today = new Date()
      var myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
      const end = moment(myToday)
      this.timer = end.format('mm:ss')
      const self = this
      setInterval(function () {
        end.add(1, 'seconds')
        self.timer = end.format('mm:ss')
      }, 1000)
    }
  },
  mounted () {
    this.setTimer()
  },
  async created () {
    try {
      const response = await axios.get('api/flashcards/flashcards/favoritos/')
      this.flashcards = response.data.results
      if (!this.flashcards.length) {
        const confirm = await this.$root.$confirm({
          message: 'Aún no has agregado ninguno.',
          acceptText: 'Ok'
        })
        if (confirm) {
          this.$router.push({ name: 'Mazos' })
        }
      }
    } catch (e) {
      this.$root.$confirm({
        message: `Error: ${e}`,
        acceptText: 'Ok'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 48px;
}

@media (max-width: 960px) {
  .list {
    margin-top: -20px !important;
  }
}
</style>
