<template>
  <v-carousel
    v-bind="$attrs"
    v-on="$listeners"
    fluid
    hide-delimiters
    :show-arrows="false"
    light
    touchless
    v-model="activeId"
    class="ma-0 pa-0"
  >
    <v-carousel-item
      v-for="(card, index) in flashcards"
      :key="index"
      class="ma-0 pa-0"
    >
      <FlashcardFavourite
        :height="flashcardHeight"
        class="m-5 mx-auto"
        :class="{'py-5': $vuetify.breakpoint.smAndDown}"
        :card="card"
        :back="activeId === 0"
        :isActiveFc="activeId === index"
        @goTo="goTo"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import FlashcardFavourite from '@/components/mazos/FlashcardFavourite.vue'

export default {
  inheritAttrs: false,
  components: {
    FlashcardFavourite
  },
  props: {
    flashcards: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeId: 0
    }
  },
  computed: {
    flashcardHeight () {
      if (this.$route.name === 'Inicio') {
        return 369
      } else {
        return 412
      }
    }
  },
  methods: {
    goTo (index) {
      this.activeId += index
      if (this.activeId === this.flashcards.length) {
        this.activeId = 0
      }
      this.$emit('setActiveId', this.activeId)
    }
  }
}
</script>
