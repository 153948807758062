<template>
  <v-navigation-drawer
    :permanent="!$vuetify.breakpoint.smAndDown"
    app
    clipped
    v-model="showDrawerInfo"
    width="349"
    class="d-flex flex-column px-15 justify-center py-8 overflow-hidden"
    style="overflow-y: hidden !important;"
  >
    <div class="scrolldrawer">
      <div class="mb-5 mx-3 d-flex cursor__pointer" @click="drawerBack">
        <v-btn icon class="mr-5">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="my-auto">
          Regresar
        </div>
      </div>
      <div>
        <p class="flashcard__text--body ml-5">
          {{ tema }}
        </p>
        <p class="flashcard__text--subtitle mb-1 ml-5">
          {{ especialidad }}
        </p>
        <p
          class="flashcard__text--subsubtitle pb-5 ml-5"
          style="border-bottom: 1px solid #EFF3F4;"
        >
          {{ area }}
        </p>
        <v-img
          v-if="$route.name==='FlashcardFavouriteSimulator'"
          src="@/assets/mazos/ajolito.svg"
          class="mx-auto"
          height="177"
          width="177"
        />
        <FlashcardDescription
          v-if="$route.name!=='FlashcardFavouriteSimulator'"
          class="ml-5"
          :flashcardInfo="historialResponse"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import FlashcardDescription from '@/components/flashcard/FlashcardDescription'
import showDrawer from '@/mixins/showDrawer'
import vuetifyHelpers from '@/mixins/vuetifyHelpers'
import { mapState } from 'vuex'

export default {
  name: 'TheDrawerInfo',
  mixins: [showDrawer, vuetifyHelpers],
  components: {
    FlashcardDescription
  },
  props: {
    area: {
      type: String,
      default: ''
    },
    especialidad: {
      type: String,
      default: ''
    },
    tema: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showDrawerInfo: false
    }
  },
  computed: {
    ...mapState('flashcard', ['historialResponse'])
  },
  methods: {
    drawerBack () {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showDrawer = false
      } else {
        if (this.$route.name === 'FlashcardFavouriteSimulator') {
          this.$router.push('/mazos')
        } else {
          this.$router.go(-1)
        }
      }
    }
  },
  watch: {
    $vuetify: {
      handler (to, from) {
        if (this.$vuetify.breakpoint.smAndUp) {
          this.showDrawerInfo = true
        } else {
          this.showDrawerInfo = false
        }
      },
      inmediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.scrolldrawer {
  max-height: 100% !important;
  overflow: auto  !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.scrolldrawer::-webkit-scrollbar {
  display: none !important;
}
</style>
