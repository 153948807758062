<template>
  <div
    class="progressbar mx-auto"
  >
    <v-card
      class="progressbar__timer d-flex justify-center align-center flex-grow-1 mr-5"
      :height="$vuetify.breakpoint.smAndDown ? '35': '65'"
    >
      <p class="progressbar__text pa-0 ma-0">
        {{ timer }}
      </p>
    </v-card>
    <FlashcardLevelBar
      :progress="progress"
      :level="level"
    />
  </div>
</template>

<script>
import FlashcardLevelBar from './FlashcardLevelBar.vue'

export default {
  components: { FlashcardLevelBar },
  props: {
    progress: {
      type: [String, Number],
      default: '100'
    },
    level: {
      type: String,
      default: ''
    },
    timer: {
      type: String,
      default: '00:00'
    }
  }
}
</script>

<style lang="scss" scoped>
.progressbar {
  margin-top: -60px;
  display: flex;
  width: 90%;
  &__timer {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
    border-radius: 10px;
    flex-basis: 23%;
  }
  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}

@media (max-width: 960px) {
  .progressbar {
    display: block;
    margin-top: -90px;
    &__timer {
      width: 100%;
      order: 2;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    &__text {
      font-size: 10px;
    }
  }
}
</style>
