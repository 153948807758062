<template>
  <div>
    <div class="d-inline-flex">
      <p class="flashcard__text--body mr-1">Estado: </p>
      <p class="general__text--subtitle">{{ currentFlashcardPhase }}</p>
    </div>
    <p class="flashcard__text--body mr-1">Repetición espaciada</p>
    <template v-if="flashcardInfo.length">
      <v-slide-y-transition>
        <p class="general__text--subtitle">Última vez visto</p>

      </v-slide-y-transition>

    </template>
    <template v-else>
      <v-slide-y-transition>
        <p class="general__text--subtitle">No has visto esta flashcard antes.</p>
      </v-slide-y-transition>
    </template>
    <transition-group
      name="list"
      mode="out-in"
    >
      <div
        v-for="(response) in flashcardInfo"
        :key="response.id"
        class="d-flex general__text--subtitle list-item"
      >
        <p style="flex-basis: 21%;">{{ day(response.fecha_respuesta) }}/{{ month(response.fecha_respuesta) }}</p>
        <p
          :class="{'text--easy': response.respuesta==='FACIL', 'text--normal': response.respuesta==='NORMAL', 'text--hard': response.respuesta==='DIFICIL' }">
          - {{ descriptionFlashcard(response.respuesta) }}</p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import flashcard from '@/mixins/flashcard'
import { mapGetters } from 'vuex'

export default {
  mixins: [flashcard],
  name: 'FlashcardDescription',
  props: {
    flashcardInfo: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('flashcard', ['currentFlashcardPhase'])
  },
  methods: {
    day (date) {
      return new Date(date).getUTCDate()
    },
    month (date) {
      return new Date(date).getUTCMonth() + 1
    }
  }
}
</script>
<style lang="scss" scoped>
.list-item {
  display: inline-flex;
  margin-right: 0;
}

.list-enter-active, .list-leave-active {
  transition: all 0.3s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}
</style>
